import request from '@/router/axios';
import {businessUrl} from "../config/env";

//新增
export const add = (row) => {
    return request({
        url: businessUrl + "qualityFeedback/insert",
        method: "post",
        data: {
            ...row
        }
    })
}

export const  list = (row) => {
    return request({
        url: businessUrl + "qualityFeedback/selectPage",
        method: "post",
        data: {
            ...row
        }
    })
}

//专用于列表展示 不做过多逻辑
export const  selectPageList = (row) => {
    return request({
        url: businessUrl + "qualityFeedback/selectPageList",
        method: "post",
        data: {
            ...row
        }
    })
}